<template>
  <div class="min-h-contain">
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Roles</h1>
        <Button buttonText="Assign Role" type="primary" @action="addData" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="flex mb-5 gap-5">
          <div class="w-80">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search by Name or Email</label>
            <div class="relative">
              <TextField type="text" withIcon borderEnabled v-model="selectedName" :enterKeyAction="onSearch" placeholder="Search by Name or Email" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search />
              </div>
            </div>
          </div>
          <div class="w-60">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter by Role</label>
            <Dropdown :options="roleOptions" v-model="selectedRole" :isReset="isResetDropdown" placeholder="Select Role" optionLabel="label" />
          </div>
          <div class="relative w-60">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter by Customer</label>
            <div class="relative">
              <TextField borderEnabled withIcon v-model="customerKeyword" @input="searchCustomer()" placeholder="Search Customer..." />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search />
              </div>
            </div>
            <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-black bg-opacity-80 backdrop-filter backdrop-blur-sm z-10" v-if="isSearching">
              <template v-if="customerList.length > 0 && !isFetching">
                <div
                  @click="selectCustomer(customer)"
                  class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                  v-for="customer in customerList"
                  :key="customer.id"
                >
                  {{ customer.name }}
                </div>
              </template>
              <template v-if="customerList.length === 0 && !isFetching">
                <p class="py-1 px-3 text-white font-normal text-center">Customer not found.</p>
              </template>
            </div>
          </div>
          <div class="my-auto pt-5">
            <Button buttonText="Apply Filter" type="secondary" size="regular" additionalClass="py-3" @action="onSearch" />
            <Button buttonText="Clear" type="tertiary" additionalClass="py-3" @action="clearParameter" class="ml-3" />
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalData"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex gap-2">
              <Button
                type="tertiary"
                size="icon"
                :forIcon="{ color: 'yellow' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                :tooltip="{ show: true, position: '170%', text: 'Edit' }"
                @action="editMaster(props.row.customerId, props.row.customerUserId)"
              />
              <Button
                type="delete"
                size="icon"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                :tooltip="{ show: true, position: '140%', text: 'Delete' }"
                @action="showDeleteConfirmModal(props.row)"
              />
            </div>
            <div v-else-if="props.column.field === 'customerRoles'">
              <template v-if="props.formattedRow[props.column.field].length > 0">
                <div v-for="customerRole in props.formattedRow[props.column.field]" :key="customerRole.roleName">
                  {{ getRoleName(customerRole.roleName) }}
                </div>
              </template>
              <template v-else> - </template>
            </div>
            <!-- <div v-else-if="props.column.field === 'admin'">
              {{ customerName }}
            </div> -->
          </template>
        </vue-good-table>
      </div>
    </div>
    <Modal :modalVisible="isDeleteConfirmationModalVisible" @close="toogleDeleteConfirmModal" width="640px" id="confirm">
      <template slot="modal-content">
        <div class="py-5">
          <div class="font-bold text-2xl">Are you sure want to remove this user?</div>
          <div class="py-5 text-sm">This account will no longer have access to several features in this environment.</div>
          <div class="flex justify-center gap-6 items-center">
            <Button buttonText="Remove" type="secondary" size="long" @action="deleteMaster" />
            <Button buttonText="Cancel" type="primary" size="long" @action="toogleDeleteConfirmModal" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { showVueToast } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  mounted: function () {
    this.initData()
    this.getRoleOptions()
    // this.clientName()
  },
  data: function () {
    return {
      params: {
        page: 0,
        size: 10,
        q: '',
        role: ''
      },
      isResetDropdown: false,
      totalData: 0,
      roleOptions: [],
      isContainsSpecialCharacter: false,
      isDeleteConfirmationModalVisible: false,
      isFetching: false,
      isSearching: false,
      currentKeyword: '',
      roleSelected: {},
      masterId: '',
      perPage: 10,
      showProgress: true,
      stillLoading: false,
      isKeywordSameTwice: false,
      customerUserId: '',
      customerName: '',
      customerKeyword: '',
      debounce: null,
      roleMapping: {
        ADMIN: 'Admin',
        MKT_ADMIN: 'Marketing Admin',
        USER: 'User',
        CMS_ADMIN: 'CMS Admin',
        REGISTRANT: 'Registrant',
        LP_CURATION: 'LP Curation',
        DP_CURATION: 'DP Curation',
        PMO_CURATION: 'PMO Curation',
        HR_ADMIN: 'HR Admin',
        HR_USER: 'HR User',
        CRM_ADMIN: 'CRM Admin',
        LMS_ADMIN: 'LMS Admin',
        LMS_INSTRUKTUR: 'LMS Instructor',
        DATA_USER: 'Data User',
        FINANCE_ADMIN: 'Finance Admin',
        EMPLOYEE: 'Employee',
        DASHBOARD_SUPERADMIN: 'Dashboard Superadmin',
        DASHBOARD_STI: 'Dashboard STI',
        DASHBOARD_SKI: 'Dashboard SKI',
        DASHBOARD_NSG: 'Dashboard NSG',
        DASHBOARD_TKI: 'Dashboard TKI',
      },
      selectedName: null,
      selectedRole: null,
      selectedCustomerId: '',
      deleteCandidate: null
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    searchCustomer() {
      if (this.customerKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.customerKeyword !== '') self.getCustomerList(self.customerKeyword)
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectCustomer(item) {
      this.selectedCustomerId = item.id
      this.customerKeyword = item.name
      this.isSearching = false
    },
    getCustomerList(keyword = '') {
      this.$store
        .dispatch('roles/GET_LIST_CUSTOMER', {
          masterId: this.customerUserId,
          params: queryString.stringify({
            q: keyword
          })
        })
        .then(() => {
          this.isSearching = true
          this.isFetching = false
        })
    },
    toogleDeleteConfirmModal() {
      this.isDeleteConfirmationModalVisible = !this.isDeleteConfirmationModalVisible
    },
    clearParameter() {
      this.params = {
        page: 0,
        size: 10
      }
      this.selectedName = null
      this.selectedRole = null
      this.selectedCustomerId = ''
      this.customerKeyword = ''
      this.isResetDropdown = !this.isResetDropdown
      this.initData()
    },
    initData() {
      this.showLoading()
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          page: this.params.page,
          size: this.params.size,
          q: '',
          customerId: ''
        },
        ...this.params
      })
      this.$store
        .dispatch('roles/GET_LIST_ROLES', {
          masterId: this.customerUserId,
          params: paramsTemp
        })
        .then((res) => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
          this.customerUserId = res.data.data.customerUserId
          this.totalData = res.data.pagination.total
          this.hideLoading()
        })
    },
    convertToTitleCase(sentence) {
      // Check if the sentence contains underscore
      if (sentence.includes('_')) {
        // Split the sentence into words
        let words = sentence.toLowerCase().split('_')

        // Capitalize the first letter of each word
        let capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })

        // Join the words with a space
        let result = capitalizedWords.join(' ')

        return result
      } else {
        // Capitalize the first letter of the sentence
        let result = sentence.charAt(0).toUpperCase() + sentence.slice(1)

        return result
      }
    },
    getRoleName(roleName) {
      const generatedRoleName = this.roleMapping[roleName]
      return generatedRoleName
    },
    onPageChange(params) {
      this.params.size = params.currentPerPage
      this.params.page = params.currentPage - 1
      this.initData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      return specialChars.test(str)
    },
    onSearch() {
      this.showLoading()
      this.params.page = 0
      this.params.q = this.selectedName
      this.params.role = this.selectedRole?.value
      this.params.customerId = this.selectedCustomerId
      let paramsTemp = queryString.stringify({
        ...{
          q: this.params.q,
          role: this.params.role?.value
        },
        ...this.params
      })
      this.$store
        .dispatch('roles/GET_LIST_ROLES', {
          masterId: this.customerUserId,
          params: paramsTemp
        })
        .then((res) => {
          this.hideLoading()
          this.totalData = res.data.pagination.total
        })
    },
    deleteMaster() {
      this.showLoading()
      let paramsTemp = queryString.stringify({
        ...{
          page: this.params.page,
          size: this.params.size,
          q: '',
          customerId: ''
        },
        ...this.params
      })
      this.$store
        .dispatch('roles/DELETE_ROLES', {
          customerId: this.deleteCandidate.customerId,
          masterId: this.deleteCandidate.customerUserId
        })
        .then((resp) => {
          if (resp.data.status == '200 OK') {
            showVueToast('User berhasil di hapus', 'success', 2000)
            this.$store.dispatch('roles/GET_LIST_ROLES', {
              params: paramsTemp,
              masterId: this.deleteCandidate.customerUserId
            })
            this.toogleDeleteConfirmModal()
          }
          this.hideLoading()
        })
        .catch((err) => {
          this.totalClass = err.data.length
          this.hideLoading()
          this.toogleDeleteConfirmModal()
          setTimeout(() => {
            this.toogleInstructorAssignmentModal()
          }, 50)
        })
    },
    getRoleOptions() {
      this.$store.dispatch('roles/GET_LIST_ROLE').then((response) => {
        if (response.data.code !== 200) return
        let self = this
        const result = response.data.data
        this.roleOptions = result.map((item) => {
          return {
            label: self.roleMapping[item],
            value: item
          }
        })
      })
    },
    editMaster(customerId, customerUserId) {
      this.$router.push(`/roles/edit/${customerId}/${customerUserId}`)
    },
    showDeleteConfirmModal(roles) {
      this.deleteCandidate = roles
      this.masterId = roles.customerUserId
      this.toogleDeleteConfirmModal()
    },
    addData() {
      this.$router.push('/' + this.$route.meta.name + '/add')
    },
    rowStyleClassFn(row) {
      let result = 'default'
      return result
    }
  },
  computed: {
    ...mapGetters('roles', ['customerList']),
    keywordTyping() {
      return this.params.q
    },
    customerId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['roles/list_roles'] ? this.$store.getters['roles/list_roles'] : []
    },
    totalRecords: function () {
      return this.$store.getters['roles/paginate'].total ? this.$store.getters['roles/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'name', label: 'Account Name' },
        { field: 'email', label: 'Email' },
        { field: 'customerRoles', label: 'Roles' },
        { field: 'customerName', label: 'Customer' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    }
  },
  created: function () {
    // this.initData()
  },
  watch: {
    keywordTyping(value) {
      if (this.containsSpecialChars(value)) {
        this.isContainsSpecialCharacter = true
        return
      }
      this.isContainsSpecialCharacter = false
    },
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    keyword(value) {
      if (value === this.currentKeyword) {
        if (!this.isKeywordSameTwice) this.isSearching = true
      } else {
        this.roleSelected = {}
        this.isKeywordSameTwice = true
        this.isSearching = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
